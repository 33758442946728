import logo from './logo.svg';
import linkedin from "./linkedin.svg"
import './App.css';

function App() {
  const handleClick = (link) => {
    window.open(link);
  };

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          {"Building something new :)"}
        </p>
        <div className={"socials-container"}>
          <div onClick={() => handleClick("https://www.linkedin.com/company/coderoom-inc")}>
            <img src={linkedin} className="social-link" alt="logo" />
          </div>
        </div>
      </header>
    </div>
  );
}

export default App;
